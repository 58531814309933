import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Title from 'components/Title/Title'
import Text from 'components/Text/Text'
import Button from 'components/Button/Button'
import useAxios from 'axios-hooks'
import _ from 'lodash'
import { Input, Spinner } from 'reactstrap'

const HANDLE_ACCEPT = 'accept'
const HANDLE_REJECT = 'decline'

const Invitation = ({ guid }) => {
	const { t } = useTranslation()
	
	const [ isLoading, setIsLoading ] = useState( null );
	const [ canSave, setCanSave ] = useState( false );
	
	const inputValue = useRef( '' )
	
	// Load the data
	const [{ data, loading, error }, loadInformation ] = useAxios(`connection/rest/invitation/information/guid/${guid}`)

	// Function for post
	const [ { data: responseData, loading: responseLoading, error: responseError }, executeResponse ] = useAxios({
		url: `connection/rest/invitation/response/guid/${guid}`,
		method: 'POST'
	}, { manual: true } )
	
	const dataStatus = _.get( data, 'status', 'not_found' );
	const dataMessage = _.get( data, 'message', null );
	
	const handleAccept = () => {
		setIsLoading( HANDLE_ACCEPT )
		
		executeResponse({
			data: {
				response: HANDLE_ACCEPT,
				license_plate: inputValue.current
			}
		})
	}
	
	const handleReject = () => {
		setIsLoading( HANDLE_REJECT )
		
		executeResponse({
			data: {
				response: HANDLE_REJECT
			}
		})
	}
	
	const validateCanSave = () => {
		let canSave = true;
		if( dataMessage === 'license_plate' && inputValue.current.length === 0 ) {
			canSave = false;
		}
		
		setCanSave( canSave )
	}
	
	const onLicensePlateChange = ( e ) => {
		inputValue.current = e.target.value
		
		validateCanSave()
	}
	
	const isAccepting = isLoading === HANDLE_ACCEPT
	const isRejecting = isLoading === HANDLE_REJECT
	
	useEffect( validateCanSave, [ dataMessage ] )
	
	if( loading ) {
		return (
			<div>
				<Text content={ t('invitation.loading') } />
				
				<Spinner color="primary" />
			</div>
		)
	}
	
	if( error ) {
		return (
			<div>
				<Text content={ t('invitation.loading_failed') } />
				
				<Button
					color="primary"
					onClick={ loadInformation }
					loading={ loading }>
					
					{ t('invitation.button_load') }
				</Button>
			</div>
		)
	}
	
	// Not found!
	if( dataStatus === 'not_found' ) {
		return (
			<div>
				<Text content={ t('invitation.not_found') } />
			</div>
		)
	}
	
	// FOUND :)
	if( dataMessage === 'past' ) {
		return (
			<>
				<Title content={ t('invitation.title') } />
				
				<Text content={ t('invitation.past') } />
			</>
		)
	}
	
	if( !responseLoading ) {
		if( isLoading !== null ) {
			setIsLoading( null )
		}
	}
	
	// For name and description?
	//const invitationData = _.get( data, 'invitation', {} );
	
	// Button is success
	if( _.get( responseData, 'message', null ) === 'success' ) {
		return (
			<>
				<Title content={ t('invitation.title') } />
				
				<Text content={ t('invitation.success') } />
			</>
		)
	}
	
	// Normal
	return (
		<>
			<Title content={ t('invitation.title') } />
			
			<Text content={ t('invitation.description') } />
			
			{/* Did we respond? */}
			{ dataMessage === 'did_respond' && (
				<Text
					bold={ true }
					content={ t('invitation.did_respond') }
				/>
			)}
			
			{ dataMessage === 'license_plate' && (
				<div className="license-container">
					<Text
						bold={ true }
						content={ t('invitation.license_plate') }
					/>
					
					<Input
						className={'license-container-input'}
						placeholder={'AA-000-A'}
						onChange={ onLicensePlateChange.bind( this ) }
					/>
				</div>
			)}
			
			<div className="button-container">
				{ dataMessage !== 'did_respond' && (
					<Button
						color="primary"
						onClick={ handleAccept.bind( this ) }
						disabled={ isRejecting || !canSave }
						loading={ isAccepting }>
						
						{ t('invitation.button_accept') }
					</Button>
				)}
				
				<Button
					color="danger"
					onClick={ handleReject.bind( this ) }
					disabled={ isAccepting }
					loading={ isRejecting }>
					
					{ t('invitation.button_decline') }
				</Button>
			</div>
		</>
	)
}

export default Invitation
