import backgroundImage from 'assets/images/background.jpg'
import logoImage from 'assets/images/logo.png'
import './App.css';
import Invitation from 'pages/invitation/Invitation'
import { configure } from 'axios-hooks'
import axiosInstance from 'helper/axiosConfig'
import { useTranslation } from 'react-i18next'
import Text from 'components/Text/Text'
import { Helmet } from "react-helmet";
import Url from 'url-parse'
import _ from 'lodash'
import Home from 'pages/Home/Home'
import { axiosConfig } from 'helper/axiosConfig'

configure({ axios: axiosInstance } )

function App() {
    const { t } = useTranslation()
    
    //
    const url = new Url( window.location.href, '', true );
    const guid = _.get( url.query, 'guid', null )
    
    return (
          <div
              className="App"
              style={{
                  backgroundImage: `url("${backgroundImage}")`,
              }}>
    
              <Helmet>
                  <meta charSet="utf-8" />
                  <title>{ t('app_name') }</title>
              </Helmet>
        
              <div className="content">
                  <div className="logo-container">
                      <img
                          className="logo"
                          src={ logoImage }
                          alt="Logo My office days"
                      />
                  </div>
                  
                  { axiosConfig.customer !== null && guid !== null ? (
                      <Invitation
                          guid={ guid }
                      />
                  ) : (
                      <Home />
                  )}
    
                  <div className="footer">
                      <Text content={ t('footer.text') } />
                      <a href="https://myofficedays.com/" target="_blank">myofficedays.com</a>
                  </div>
              </div>
          </div>
    );
}

export default App;
