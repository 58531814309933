import React from 'react'

const Title = ({ content = null }) => {
	return (
		<h1>
			{ content }
		</h1>
	)
}

export default Title
