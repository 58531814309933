import axios from 'axios'
import Config from 'config'
import Url from 'url-parse'
import _ from 'lodash'
import customerDomainReplaces from 'customerDomainReplaces'

const url = new Url( window.location.href, '', true );
const customer = _.replace( url.pathname, '/', '' );

const getCustomerDomain = () => {
	return _.get( customerDomainReplaces.list, customer, customer );
}

export const axiosConfig = {
	customer: customer,
	baseURL: `${ Config.serverUrlV2.replace('{{customer}}', getCustomerDomain() ) }/${ customer }`,
	//timeout: 1000
}

const axiosInstance = axios.create( axiosConfig );

export default axiosInstance;
