import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Lang
import transTranslationEN from 'locales/en-GB/translation.json';
import transTranslationNL from 'locales/nl-NL/translation.json';

i18n
	.use( LanguageDetector )
	.use( initReactI18next ) // bind react-i18next to the instance
	.init({
		fallbackLng: 'nl',
		debug: true,
		
		order: [
			'querystring',
			//'cookie',
			//'localStorage',
			//'sessionStorage',
			//'navigator',
			//'htmlTag',
			//'path',
			//'subdomain'
		],
		
		resources: {
			'nl': {
				translation: transTranslationNL
			},
			'en': {
				translation: transTranslationEN
			}
		},
		
		interpolation: {
			escapeValue: false, // not needed for react!!
		},
	});

export default i18n;
