import React from 'react'

const Text = ({ content = null, bold }) => {
	return (
		<p
			style={{
				fontWeight: bold === true ? 'bold' : 'inherit'
			}}>

			{ content }
		</p>
	)
}

export default Text
