import React from 'react'
import { useTranslation } from 'react-i18next'
import Title from 'components/Title/Title'
import Text from 'components/Text/Text'

const Home = () => {
	const { t } = useTranslation()
	
	return (
		<>
			<Title content={ t('home.title') } />
			
			<Text content={ t('home.description') } />
		</>
	)
}

export default Home
